import * as React from "react"
import { useState } from "react"

import { StaticImage } from "gatsby-plugin-image"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import "../styles/components/Header.styl"

export default function Header() {
  const [headerStatus, setHeaderStatus] = useState("Header--closed")
  const [barsRotation, setBarsRotation] = useState("notRotated")

  function handleHeaderLinksDisplay() {
    openHeader()
    rotateIcon()
  }

  function openHeader() {
    if (headerStatus === "Header--closed") {
      setHeaderStatus("Header--open")
    } else setHeaderStatus("Header--closed")
  }

  function rotateIcon() {
    if (barsRotation === "notRotated") {
      setBarsRotation("Header__links__alternative-icon--rotated")
    } else setBarsRotation("notRotated")
  }
  const blue = "#03588c"
  return (
    <header className={`Header ` + headerStatus}>
      <div className="Header__logo">
        <AniLink paintDrip hex={blue} duration={0.5} to="/">
          <StaticImage
            src="../images/photon-white.png"
            alt="white ball PHOTON letters"
            backgroundColor="transparent"
            placeholder="tracedSVG"
            quality="100"
          />
        </AniLink>
      </div>
      <div className="Header__links">
        <AniLink cover bg={blue} duration={0.5} to="/Paneles-solares">
          Paneles solares
        </AniLink>
        <AniLink paintDrip hex={blue} duration={0.5} to="/Calentadores-solares">
          Calentadores Solares
        </AniLink>
        <AniLink cover bg={blue} duration={0.5} to="/colectores-para-alberca">
          Colectores para Alberca
        </AniLink>
        <AniLink paintDrip hex={blue} duration={0.5} to="/acerca-de-nosotros">
          Acerca de nosotros
        </AniLink>
        <button
          className={`Header__links__alternative-icon ` + barsRotation}
          onClick={handleHeaderLinksDisplay}
        >
          ☰
        </button>
      </div>
    </header>
  )
}
